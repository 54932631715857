var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = {
  name: "icon-windows",
  body: function iconWindows() {
    if ((this || _global).childElementCount) return;
    (this || _global).innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" preserveAspectRatio=\"xMidYMid meet\" width=\"100%\" height=\"100%\" viewBox=\"0 0 13 13\">" + "<g>" + "<path d=\"M6.000,6.000 C6.000,6.000 6.000,1.077 6.000,1.077 C6.000,1.077 13.000,-0.000 13.000,-0.000 C13.000,-0.000 13.000,6.000 13.000,6.000 C13.000,6.000 6.000,6.000 6.000,6.000 ZM-0.000,6.000 C-0.000,6.000 -0.000,2.000 -0.000,2.000 C-0.000,2.000 5.000,1.231 5.000,1.231 C5.000,1.231 5.000,6.000 5.000,6.000 C5.000,6.000 -0.000,6.000 -0.000,6.000 ZM5.000,7.000 C5.000,7.000 5.000,11.769 5.000,11.769 C5.000,11.769 -0.000,11.000 -0.000,11.000 C-0.000,11.000 -0.000,7.000 -0.000,7.000 C-0.000,7.000 5.000,7.000 5.000,7.000 ZM13.000,7.000 C13.000,7.000 13.000,13.000 13.000,13.000 C13.000,13.000 6.000,11.923 6.000,11.923 C6.000,11.923 6.000,7.000 6.000,7.000 C6.000,7.000 13.000,7.000 13.000,7.000 Z\" id=\"path-1\" style=\"fill:inherit\" fill-rule=\"evenodd\"></path>" + "</g>" + "</svg>";
  }
};
export default exports;